@import '../wtp-color-scheme.scss';

.App-footer {
    opacity: .5;
    color: $ThemeExtraLightMono;
    background-color: $ThemeHeadingBackground;
    display: flex;
    flex-direction: column;
    padding: 2rem 0 .5rem;
    margin-top: auto;
    width: 100%;
    justify-content: center;
    .footer-legal {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: center;
        a {
            color: $ThemeExtraLightMono;
            text-decoration: none;

            &:visited {
                color: $ThemeExtraLightMono;
            }
            &:hover {
                color: #ccc;
            }
        }
    }
}
@media only screen and (max-width: 768px) {
    .App-footer {
        font-size: 70%;
    }
}