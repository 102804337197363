$TxtColor: #2d545e;
$DarkTxtColor: #12343b;
$HoverColor: #2899b0;
$LtBackground: #e1b382;
$LtBackgroundShadow: #c89666;
$MainHeadingColor: #0c6a7d;
$PortfolioNavHeaderColor: #0e94b0;

$ThemeBlue: #429fe8;
$ThemeGold: #ffba00;
$ThemeGreen: #3cff74;
$ThemeYellow: #f5ee2f;
$ThemeRed: #f54a39;
$ThemePurple: #a939ff;
$ThemeMono: #444;
$ThemeLightMono: #999;
$ThemeExtraLightMono: #eee;
$ThemeTextBoxBackground: #282c34;
$ThemeHeadingBackground: #3D434F