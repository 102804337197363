@import './wtp-color-scheme.scss';

html,body {
    font-family: 'Raleway', sans-serif;
    font-weight: 400;
    font-size: clamp(.8em, calc(50% + .5vw), 1.5em);
    color: $ThemeExtraLightMono;
    background-color: $ThemeHeadingBackground;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

body {
  height: 100%;
  margin: 0;
}
