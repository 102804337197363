@import '../wtp-color-scheme.scss';

.App-header {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin: 0 auto;
    padding: 2vh clamp(1rem, calc((100vw - 80rem) / 2), 50rem);
    background-color: $ThemeTextBoxBackground;
    justify-content: center;
    a:link {
        text-decoration: none;
    }
    .logo-block {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: baseline;
        @media all and (max-width: 1159px) {
            align-items: center;
        }
        .logo {
            display: flex;
            font: small-caps 700 5rem 'Josefin Sans', sans-serif;
            font-size: clamp(2rem, calc(1.5 * 3vw), 4rem);
            margin: 0 auto;
            .logo-first {
                padding-top: .25em;
                color: $ThemeGold;
                .logo-spacing {
                    letter-spacing: -.15em;
                }
            }
            .logo-last {
                margin-left: -.6rem;
                padding-top: .25em;
                color: white;
                .logo-spacing-last {
                    letter-spacing: -.11em;
                }
            }
        }
        .logo-tagline {
            display: flex;
            justify-content: flex-start;
            text-align: center;
            max-width: 45vw;
            margin: 0 auto  ;
            font: 300 1.5em/1 'Prata';
            font-size: clamp(1rem, calc(.5 * 2.7vw), 1.5rem);
            letter-spacing: 2px;
            color: #27a1e7;
            word-wrap: normal;
        }
    }
}
