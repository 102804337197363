@import '../wtp-color-scheme.scss';

.social-icons {
    display: flex;
    width: 100%;
	color: $ThemeExtraLightMono;
    justify-content: center;
    align-items: center;
    a {
        margin-left: 1em;
        display: inline-flex;
        img {
            width: 1.5em;
            height: 1.5em; 
        }
    }
}
