.nf-page-content-container {
    display: flex;
    flex-direction: column;       
	width: 100%;
}

.nf-page-content {
	display: flex;
	flex-direction: row;
}

.nf-page-content-text {
	width: 50%;
}

.nf-404 {
	color: red;
	width: 100%;
	font: italic normal 700 800%/80% Robo-Slab, serif;
	text-align: center;
	text-shadow: 2px 2px 8px #666;
}

@media screen and (max-width: 375px) {
	.nf-404 {
		font-size: 200%;
	}
}

@media screen and (min-width: 376px) and (max-width: 768px) {
	.nf-404 {
		font-size: 400%;
	}
}

.nf-img-box {
	width: 50%;
}
