@import url('https://fonts.googleapis.com/css2?family=Material+Icons&display=swap');

@import './wtp-color-scheme.scss';

* {
  box-sizing: border-box;
}

h1, h2, h3, h4, h5, h6 {
  font-family: 'Prata', serif;
  color: $ThemeBlue;
}

h1 {
  color: white;
}

h2 {
  color: honeydew;
}

h3 {
  color: coral;
}



h1, h2, h3, h4, h5, h6 {
  font-weight: 500;
}
h1 {
  font-size: 2.5em;
  font-variant: all-small-caps;
  letter-spacing: 1px;
  margin: 0 0;
  padding: .67em 0;
  background-color: $ThemeHeadingBackground;
}
figcaption {
	font-style: italic
}
h2 {
  font-size: 1.5em;
}

ul {
  list-style-type: none;
  a {
    color: $TxtColor;
    text-decoration: none;
    &:visited {
      color: $TxtColor;
    }
    &:hover {
      color: $HoverColor;
    }
    &.active {
      color: $HoverColor;
    }
  }
}

blockquote {
	font-style: italic;
	font-size: 1em;
	font-weight: 500;
	line-height: 1.25em;
	margin: 1.5em .7em;
	padding: 8px;
  p {
    display: inline;
  }
  cite {
  	font-size: 70%;
  	font-weight: 400;
  }
}

.sb-block-quote {
	font-family: 'Raleway', serif;
	font-size: 1.2em;
	font-weight: 600;
	p:before {
		font-family: 'EB Garamond', serif;
		content: open-quote;
		font-size: 1.4em;
		line-height: .1em;
		margin-right: 0.15em;
		vertical-align: -0.1em;
	}
	p:after {
		font-family: 'EB Garamond', serif;
		content: close-quote;
		font-size: 1.4em;
		line-height: .1em;
		margin: 0 0.1em;
		vertical-align: -0.1em;
	}
}

.material-icons {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 24px;  /* Preferred icon size */
  display: inline-block;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;

  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;

  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;

  /* Support for IE. */
  font-feature-settings: 'liga';
}

#root {
	display: flex;
	min-height: 100vh;
	flex-direction: column;
  justify-content: flex-start;
  .App-main-content {
    display: flex;
    flex-direction: column;
    h1 {
      text-align: center;
    }
  }
}

.grecaptcha-badge {
  visibility: hidden;
}

@media only screen and (max-width: 768px) {
  #root {
    .App-main-content {
        &>h1 {
            margin-top: .5em;
        }
    }
	}
}
