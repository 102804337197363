@import '../wtp-color-scheme.scss';

.nav-list-accordian-head {
    flex-direction: column;
    position: relative;
    color: $ThemeGold;
    margin: 0 1em 0 0;
    z-index: 100;
    cursor: pointer;
    &::after {
        content: "\276f";
        display: inline-block;
        margin-left: 1rem;
        padding-left: .5rem;
        transform: rotate(90deg);
        color: $ThemeBlue;
        opacity: .5;
    }
    .nav-list-accordian-body {
        position: absolute;
        background-color: $ThemeTextBoxBackground;
        left: 0;
        margin: 0 0;
        padding-left: 1rem;
        width: clamp(20rem, 15%, 30rem);
        flex-direction: column;
        display:flex;
        visibility: hidden;
        transition-property: visibility;
        transition-duration: .5s;
        a {
            color: coral;
            &:visited {
                color: coral;
            }
            &:link {
                text-decoration: none;
            }
            &:hover {
                color: $ThemeLightMono;
            }
        }
    }
    &:hover {
        color: $ThemeLightMono;
        &::after {
            transform: rotate(-90deg);
            padding-left: 0;
            padding-right: .5rem;
        }
        .nav-list-accordian-body {
            visibility: visible;
        }
    }
    .nav-list-accordian-head-1 {
        margin: 0 0;
        width: calc(15vw - 3rem);
        color: coral;
        .nav-list-accordian-body {
            position: relative;
            width: calc(15vw - 2rem);
            display: none;
            flex-direction: column;
            a {
                color: $ThemeMono;
                &:visited {
                    color: $ThemeMono;
                }
                &:link {
                    text-decoration: none;
                }
                &:hover {
                    color: $ThemeLightMono;
                }
            }
        }
        &:hover {
            .nav-list-accordian-body {
                display: flex;
            }
        }
        .nav-list-accordian-head-2 {
            position: relative;
            padding-left: 1rem;
            width: calc(15vw - 4rem);
            & > .nav-list-accordian-body {
                position: relative;
                width: calc(30vw - 6rem);
                display: none;
                flex-direction: column;
                a {
                    color: $ThemeMono;
                    &:visited {
                        color: $ThemeMono;
                    }
                    &:link {
                        text-decoration: none;
                    }
                    &:hover {
                        color: $ThemeLightMono;
                    }
                }
            }
        }
        &:hover {
            .nav-list-accordian-body {
                display: flex;
            }
        }
    }
    .material-icons.nav-more {
        vertical-align: middle;
    }
}

@media screen and (max-width: 768px) {
    .nav-list-accordian-head {
        color: $ThemeMono;
        font-size: 1.5em;
        margin: 1em 0 0;
        width: calc(100% - 1em);
        .nav-list-accordian-body {
            position: inherit;
            visibility: visible;
            width: calc(100% - 5vw);
            padding-left: 5vw;
            background-color: inherit;
            .nav-list-accordian-head-1 {
                width: 100%;
                .nav-list-accordian-body {
                    width: 100%;
                }
            }
        }
        &::after {
            display: none;
        }
    }
}
