@import '../wtp-color-scheme.scss';

.App-primary-nav {
    .primary-nav-bar {
        display: flex;
        justify-content: space-between;
        gap: 0 1rem;
        padding-top: 1.5em;
        font-size: calc(.5rem + 1vmin);
        .nav-list-accordian-head, .nav-button {
            margin: 0;
        }
        .nav-button.cart {
            position: relative;
            left: 5vw;
            text-align: right;
        }
        @media screen and (max-width: 1250px) {
            .nav-button.cart {
                position: relative;
                left: 2vw;
                text-align: right;
            }
        }    
    }
    .nav-buttons {
        display: none;
        margin: 1em 0 0;
        justify-content: space-evenly;
    }
    .primary-nav-list {
        color: $ThemeMono;
        &:hover {
            color: $ThemeLightMono;
        }

        a {
            color: $ThemeMono;
            &:visited {
                color: $ThemeMono;
            }
            &:link {
                text-decoration: none;
            }
            &:hover {
                color: $ThemeLightMono;
            }
        }
    }
}

.hamb-nav-close {
    &.material-icons {
        display: none;
        z-index: 200;
        font-size: 2.5em;
        font-weight: bold;
        vertical-align: middle;
        color: #f8f8f8;
        border-radius: 50%;
        background-color: $ThemeMono;
    }
}

@media screen and (max-width: 768px) {
    .App-primary-nav {
        width: 100%;
        .nav-buttons {
            display: flex;
        }
        .primary-nav-bar {
            display: none;
        }
    }
    section.navigation {
        position: relative;
        a {
            display: block;
            padding: .5em 0;
        }
    }

    .hamb-nav-close {
        &.open {
            display: block;
            position: fixed;
            top: .5em;
            right: 5%;
            z-index: 200;
        }
    }
    .primary-nav-list {
        position: fixed;
        left: 0;
        top: 0;
        bottom: 0;
        margin: 0 0;
        padding: 5em 1em;
        width: calc(100% - 2em);
        max-height: calc(100% - 6em);
        overflow-y: auto;

        transform: translateX(110%);
        background-color: #f8f8f8;
        transition: transform .5s;
        z-index: 100;
        // padding-top: calc(1em + 1px); // height of initial <hr>

        &.open {
            transform: initial;
        }
    }
    .portfolio-nav-list {
        position: fixed;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        padding: 3em 1em;
        width: calc(100% - 2em);
        max-height: calc(100% - 6em);
        z-index: 100;
        overflow-y: auto;
        
        transform: translateX(-110%);
        background-color: #f8f8f8;
        transition: transform .5s;

        &.open {
            transform: initial;
        }
        .folder {
            padding: .5em 0 .5em 5%;
        }
        .special-edition-nav {
            li:last-child {
                padding-bottom: 2em;
            }
        }
    }
}
