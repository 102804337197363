@import '../wtp-color-scheme.scss';

.nav-button {
    text-align: center;
    color: $ThemeGold;
    margin: 0 1em;
    background-color: inherit;
    border: none;
    a {
        color: $ThemeGold;
        &:visited {
            color: $ThemeGold;
        }
        &:link {
            text-decoration: none;
        }
        &:hover {
            color: $ThemeLightMono;
        }
    }

}
@media screen and (max-width: 768px) {
    .nav-button {
        font-size: 1.5em;
    }
}
